export const dataProcess = [
    {  
       accHeader: 'Adquisición de información',
       accBody: 'Adquisición de información de la empresa a través de un cuestionario con el objetivo de conocer sobre su filosofía, entorno, misión, visión, publico objetivo, productos y otros datos relevantes.',
    },
    {  
       accHeader: 'Análisis',
       accBody: 'Analisis de la información obtenida, busqueda de puntos comunes y diferenciadores con respecto a la competencia, sitentización de los valores y objetivos de la empresa.',
    },
    {  
       accHeader: 'Desarrollo de líneas de diseño',
       accBody: 'Desarrollo de diversos conceptos que simbolicen a la marca y bocetación de los mismos.',
   },
   {  
       accHeader: 'Definición',
       accBody: 'Elección de linea de diseño, ajustes y vectorización final.',
   },
   {  
       accHeader: 'Paleta de color y tipografía',
       accBody: 'Elección definiva de paleta de color para formatos digitales y físicos. Elección de la tipografía del logotipo y el eslogan.',
   },
   {  
       accHeader: 'Versiones',
       accBody: 'Creación de versiones de la marca en distintos formatos y sobre distintos fondos.',
   },
   {  
       accHeader: 'Complementos',
       accBody: 'Diseño de elementos complementarios de la marca. Elección de tipografías para utilización en la papelería, web y resto de elementos pertenecientes a la marca. ',
   },
   {  
       accHeader: 'Manual de marca',
       accBody: 'Creación de manual con las versiones del logo original, positivo, negativo, continuo, horizontal, vertical, mínimo, convivencia, grilla, usos incorrectos, paleta de color, tipografía, elementos complementarios y otros.',
   },
   {  
       accHeader: 'Aplicaciones',
       accBody: 'Diseño de papelería y otros items aplicando el logo y los elementos complementarios de la marca previamente realizados.',
   },
   {  
       accHeader: 'Página Web',
       accBody: 'Creación de página web utlizando elementos de la marca. Elección y adaptación de plantilla en el CMS Wordpress para futura administración del cliente.',
   },
    
 ]
    
 
 
 export const dataItem = [
    {  
       itemTitle: 'Líneas de diseño',
       itemDesc: 'Buscando alternativas para el isotipo se trabajo sobre conceptos que abarcaran los servicios de la empresa de rastreo y monitoreo, combinados en el primer caso con un aguila como simbolo de seguridad y vigilancia y en el segundo con un toro enojado, como simbolo de seguridad y a su vez de ganado.',
    },
    {  
       itemTitle: 'Concepto isotipo',
       itemDesc: 'Para representarlo se escogió realizar una figura geométrica que hace referencia a el toro, la ruta, el símbolo de ubicación y un escudo. La forma que combina rectas y curvas tiene terminaciones en punta que trasmiten agresividad, se perciben a simple vista dos cuernos que representan al toro y a su vez también dos rutas por su línea blanca central. Su forma también remite a un símbolo de ubicación por su parte superior curva casi circular, y su terminación en punta hacia abajo, que también hace que se asemeje a un escudo.',
    },
    {  
       itemTitle: 'Color',
       itemDesc: 'Los colores utilizados para la marca son el rojo en representación del toro, la carne y como señal de alerta, debido a que simboliza el peligro. El rojo escogido tiene un poco mas de magenta que de amarillo y es un poco desaturado, lo que le da un toque de originaidad y hace que combine a la perfección con el segundo color. Este es un gris claro en respresentación de la tecnolgía, que a su vez también es el color de la carretera. El mismo aporta seriedad a la marca y complementa al rojo para comunicar seguridad y tecnología, logrando un gran contraste, lo cual hace que un color sea legible colocado sobre el otro.',
    },
    {  
        itemTitle: 'Logotipo y eslogan',
        itemDesc: 'Para el logotipo y el eslogan se escogió la tipografía Hurme Geometric Sans 1 Bold, por ser sólida, geometrica y tener terminaciones puntiagudas al igual que el isotipo. Por su modernidad también se asocia a la tecnología y su peso y geometería comunican hermetismo y seguridad.',
    },
    {  
        itemTitle: 'Imagotipo',
        itemDesc: 'Para el imagotipo en su versión principal se decidió colocar el isotipo del lado izquierdo usando el rojo corporativo y para el logotipo a su derecha el gris de la marca con el objetivo de destacar el isotipo. Los elementos se encuentran centrados horizontalmente de forma visual para lograr una composición equilibrada. El eslogan se coloca debajo del logotipo ocupando el mismo ancho que este.',
    },
    {  
        itemTitle: 'Variantes de color',
        itemSubtitle: 'Positivo una tinta',
        itemDesc: 'Versión del logo sobre fondos claros con una sola tinta, utilización de colores corporativos o negro #000000 / Pantone Black 6 C.',
     },
     {  
        itemTitle: 'Variantes de color',
        itemSubtitle: 'Positivo varias tintas',
        itemDesc: 'Versión del logo sobre fondos claros con mas de una tinta, utilización de colores corporativos o negro #000000 / Pantone Black 6 C.',
     },
     {  
        itemTitle: 'Variantes de color',
        itemSubtitle: 'Negativo una tinta',
        itemDesc: 'Versión del logo sobre fondos oscuros con una sola tinta, utilización de colores corporativos o negro #000000 / Pantone Black 6 C, logo #ffffff / papel blanco.',
     },
     {  
        itemTitle: 'Variantes de color',
        itemSubtitle: 'Negativo varias tintas',
        itemDesc: 'Versión del logo sobre fondos oscuros con más de una tinta, utilización de colores corporativos o negro #000000 / Pantone Black 6 C, blanco #ffffff / papel blanco.',
     },
    {  
       itemTitle: 'Tipografía web y papelería',
       itemDesc: 'Para uso en web y papelería de la marca se busco una tipografía moderna que fuese legible en todos los toportes y en tamaño pequeño. La elección fue Roboto, tipografía que cuenta con una dobe naturaleza, tiene un esqueleto mecánico y las formas son en gran parte geométricas. Al mismo tiempo, la fuente presenta curvas amigables y abiertas. Mientras que algunos grotescos distorsionan sus formas de letras para forzar un ritmo rígido, Roboto no se compromete, permitiendo que las letras se asienten en su ancho natural. Esto crea un ritmo de lectura más natural que se encuentra más comúnmente en los tipos humanistas y serif.',
    },
    {  
        itemTitle: 'Formas abstractas',
        itemDesc: 'Como elemento complementario gráfico se decidió crear formas geométricas abstractas que acompañaran al isotipo en algunas aplicaciones y comunicaran la esencia de la marca. Estas son similares a caminos y a su vez a la forma del ADN, en representación de la información genética y del monitoreo durante el recorrido de los vacunos.',
     },
    {  
       quoteText: 'El diseño es el método de juntar la forma y el contenido. El diseño es simple, por eso es tan complicado.',
       quoteAutor: 'Paul Rand',
    },
 
 ]